<template>
  <span v-if="code" :class="flagCountryIcon" class="e-flag-icon"></span>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  name: "FlagIcon",
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagCountryIcon() {
      return `fi fi-${this.code?.toLowerCase()}`
    },
  },
})
</script>

<style>
@import "flag-icons/css/flag-icons.min.css";
</style>
